import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import styled, { keyframes } from 'styled-components';
import { MapPinIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: 'Freelancer',
    id: 'tier-freelancer',
    href: '#',
    price: { monthly: '$15', annually: '$144' },
    description: 'The essentials to provide your best work for clients.',
    features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
    mostPopular: false,
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '#',
    price: { monthly: '$30', annually: '$288' },
    description: 'A plan that scales with your rapidly growing business.',
    features: [
      '25 products',
      'Up to 10,000 subscribers',
      'Advanced analytics',
      '24-hour support response time',
      'Marketing automations',
    ],
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    price: { monthly: '$48', annually: '$576' },
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations',
      'Custom reporting tools',
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const jobs = [
    {
      jobTitle: 'Senior Software Engineer',
  companyName:'The Home Depot',
  backgroundColor:'orange',
  logoPath:'./company_logos/homedepot.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Project Manager',
  companyName:'The Home Depot',
  backgroundColor:'black',
  logoPath:'./company_logos/3M.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Software Engineer',
  companyName:'The Home Depot',
  backgroundColor:'lightgrey',
  logoPath:'./company_logos/airbnb.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Director of Engineering',
  companyName:'The Home Depot',
  backgroundColor:'darkblue',
  logoPath:'./company_logos/amazon.webp',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Product Manager',
  companyName:'The Home Depot',
  backgroundColor:'darkgrey',
  logoPath:'./company_logos/apple.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Lead Engineer',
  companyName:'The Home Depot',
  backgroundColor:'red',
  logoPath:'./company_logos/bofa.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'QA Engineer',
  companyName:'The Home Depot',
  backgroundColor:'lightblue',
  logoPath:'./company_logos/capone.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Backend Engineer',
  companyName:'The Home Depot',
  backgroundColor:'red',
  logoPath:'./company_logos/delta.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Engineering Manager',
  companyName:'The Home Depot',
  backgroundColor:'purple',
  logoPath:'./company_logos/fedex.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Product Owner',
  companyName:'The Home Depot',
  backgroundColor:'orange',
  logoPath:'./company_logos/Fiserv.jpeg',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Head of Engineering',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/ford.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'VP of Engineering',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/google.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Software Engineer',
  companyName:'Snapchat',
  backgroundColor:'yellow-400',
  logoPath:'./company_logos/snapchat.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Senior Software Engineer',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/jpmorgan.jpg',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Systems Engineer Sr',
  companyName:'Lockheed Martin',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/lockheedmartin.jpg',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Software Engineer',
  companyName:'Lyft',
  backgroundColor:'pink-400',
  logoPath:'./company_logos/lyft.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'ML Engineer',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/Meta.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Senior Software Engineer',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/microsoft.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Business Analyst',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/morganstanley.jpg',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'CTO',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/ncr.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Engineering Manager',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/netflix.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Machine Learning Engineer',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/nvidia.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'IT Engineer - SRE',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/openai.jpg',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Job Title',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/paypal.png',
  interview:1,
  offer:1
  },
  
    {
      jobTitle: 'Job Title',
  companyName:'The Home Depot',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/raytheon.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Senior Manager, Operations',
  companyName:'Salesforce',
  backgroundColor:'blue-200',
  logoPath:'./company_logos/Salesforce.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Sales Representative',
  companyName:'State Farm',
  backgroundColor:'red-400',
  logoPath:'./company_logos/statefarm.webp',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Equipment Engineer, Service Operations',
  companyName:'Tesla',
  backgroundColor:'red-400',
  logoPath:'./company_logos/tesla.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Machine Learning Engineer',
  companyName:'Tik Tok',
  backgroundColor:'black',
  logoPath:'./company_logos/tiktok.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Data Engineer',
  companyName:'Zoom',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/zoom.webp',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'iOS Developer',
  companyName:'Uber',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/uber.jpg',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Job Title',
  companyName:'Verizon',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/verizon.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Job Title',
  companyName:'Visa',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/visa.png',
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Software Engineer',
  companyName:'GitHub',
  backgroundColor:'slate-900',
  logoPath:'./company_logos/github.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Senior Software Engineer',
  companyName:'Warner Bros',
  backgroundColor:'blue-800',
  logoPath:'./company_logos/warnerBros.svg.png',
  applied:1,
  interview:1,
  offer:1
  },
    {
      jobTitle: 'Director of Engineering',
  companyName:'X',
  backgroundColor:'orange-400',
  logoPath:'./company_logos/x.png',
  interview:1,
  offer:1
  }
  
  ]
  
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // Generate a random index between 0 and i
        let j = Math.floor(Math.random() * (i + 1));
        // Swap elements at indices i and j
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  
  
  const backgroundFade = keyframes`
  0% {
    background-color: #f79533;
  }
  14% {
    background-color: #f37055;
  }
  28% {
    background-color: #ef4e7b;
  }
  42% {
    background-color: #a166ab;
  }
  56% {
    background-color: #5073b8;
  }
  70% {
    background-color: #1098ad;
  }
  84% {
    background-color: #07b39b;
  }
  100% {
    background-color: #6fba82;
  }
  `;
   
  const StyledButton = styled.button`
  
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: white;
    border-radius: 11px;
    animation: ${backgroundFade} 3s infinite;
    outline: none;
  
  
  
  `;
  const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(4);
  
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1;
  `;
  
  
  
  const BackgroundGrid = styled.div`
    position: absolute;
    
    top: -400px;
    left: -140px;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(5, 1fr);
    z-index: 0;
    transform: rotate3d(1, -1, 1, 45deg);

  `;
  // overflow: hidden;
  // position: relative; 
  
  
  // Animation for moving rows
  const moveRow = (speed) => keyframes`
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  `;
  
  // Styled grid row
  const GridRow = styled.div`
    display: flex;
    animation: ${(props) => moveRow(props.speed)} ${(props) => props.speed}s linear infinite;
    height: 100%;
  `;
  
  // Styled grid cell
  const GridCell = styled.div`
  width: 350px;
  height: 300px;
  
    flex: 1;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    &:last-child {
      border-right: none;
    }
  `;
  const JobInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: white;
    text-align: center;
  `;
  
  const CompanyName = styled.div`
    font-weight: bold;
  `;
  
  const Location = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  `;
  
  const Logo = styled.img`
  width:40%;
    margin: 10px auto;
  `;
  
  const Stage = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  `;
  
  const Circle = styled.span`
    height: 5px;
    width: 5px;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  `;
  

export default function SignUp() {
  const [frequency, setFrequency] = useState(frequencies[0])
  const rowSpeeds = [80, 60, 70, 100, 80, 100, 80]; 
  const rowPosition= [0,300,600,900,1200,1500,1800]

  const [shuffledJobs, setShuffledJobs] = useState([]);
  const [shuffledJobs1, setShuffledJobs1] = useState([]);
  const [shuffledJobs2, setShuffledJobs2] = useState([]);
  const [shuffledJobs3, setShuffledJobs3] = useState([]);
  const [shuffledJobs4, setShuffledJobs4] = useState([]);

  // Shuffle jobs when the component mounts
  useEffect(() => {
    setShuffledJobs(shuffleArray([...jobs])); 
    setShuffledJobs1(shuffleArray([...jobs])); 
    setShuffledJobs2(shuffleArray([...jobs])); 
    setShuffledJobs3(shuffleArray([...jobs])); 
    setShuffledJobs4(shuffleArray([...jobs])); 
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div style={{position: "relative", isolation: "isolate", overflow: "hidden", backgroundColor: 'rgba(0,0,0,.9)'}}>
            <BackgroundGrid >
            <Overlay />
        {/* {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
        <GridRow style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}>
            {Array.from({ length: 50 }).map((_, cellIndex) => (
            <GridCell key={cellIndex} />
            ))}
        </GridRow>
        ))} */}
        {/* {Array.from({ length: numberOfRows }).map((_, rowIndex) => ( */}

        <GridRow  style={{position:'absolute', top: rowPosition[0]}} key={0} speed={rowSpeeds[0]}>
        {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
        {/* {jobs.map((job, index) => ( */}
        {shuffledJobs.map((job, index) => (

            <GridCell  >  
            {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
            <JobInfo  >
                <CompanyName>{job.jobTitle}</CompanyName>
                <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>

        ))} 
        </GridRow>
        <GridRow  style={{position:'absolute', top: rowPosition[1]}} key={1} speed={rowSpeeds[1]}>
        {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
        {/* {jobs.map((job, index) => ( */}
        {shuffledJobs1.map((job, index) => (

            <GridCell  >  
            {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
            <JobInfo  >
                <CompanyName>{job.jobTitle}</CompanyName>
                <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>

        ))} 
        </GridRow>
        <GridRow  style={{position:'absolute', top: rowPosition[2]}} key={2} speed={rowSpeeds[2]}>
        {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
        {/* {jobs.map((job, index) => ( */}
        {shuffledJobs2.map((job, index) => (

            <GridCell  >  
            {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
            <JobInfo  >
                <CompanyName>{job.jobTitle}</CompanyName>
                <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>

        ))} 
        </GridRow>

        <GridRow  style={{position:'absolute', top: rowPosition[3]}} key={3} speed={rowSpeeds[3]}>
        {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
        {/* {jobs.map((job, index) => ( */}
        {shuffledJobs3.map((job, index) => (

            <GridCell  >  
            {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
            <JobInfo  >
                <CompanyName>{job.jobTitle}</CompanyName>
                <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>

        ))} 
        </GridRow>
        <GridRow  style={{position:'absolute', top: rowPosition[4]}} key={4} speed={rowSpeeds[4]}>
        {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
        {/* {jobs.map((job, index) => ( */}
        {shuffledJobs4.map((job, index) => (

            <GridCell  >  
            {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
            <JobInfo  >
                <CompanyName>{job.jobTitle}</CompanyName>
                <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>

        ))} 
        </GridRow>
        {/* ))} */}
        {/* {jobs.map((job, index) => (
        <GridRow key={index} style={{ backgroundColor: job.backgroundColor ,}}>
            <GridCell>
            <JobInfo>
                <CompanyName>{job.companyName}</CompanyName>
                <Location><ChevronRightIcon /> location</Location>
                <Logo src={job.logoPath} alt="logo" />
                <Stage><Circle />Applied</Stage>
                <Stage><Circle />Interviewing</Stage>
                <Stage><Circle />Offer</Stage>
            </JobInfo>
            </GridCell>
        </GridRow>
        ))} */}

        </BackgroundGrid>
        <div className="bg-gray-900  " style={{ height: '100vh', marginTop: 'auto', marginBottom:'auto', overflow:'scroll' }}>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 relative">
           
           
            <div className="mt-16 flex justify-center">
            <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white"
            >
                <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                {frequencies.map((option) => (
                <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                    classNames(checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                    }
                >
                    <span>{option.label}</span>
                </RadioGroup.Option>
                ))}
            </RadioGroup>
            </div>
            <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {tiers.map((tier) => (
                <div
                key={tier.id}
                className={classNames(
                    tier.mostPopular ? 'bg-white/5 ring-2 ring-indigo-500' : 'ring-1 ring-white/10',
                    'rounded-3xl p-8 xl:p-10'
                )}
                >
                <div className="flex items-center justify-between gap-x-4">
                    <h3 id={tier.id} className="text-lg font-semibold leading-8 text-white">
                    {tier.name}
                    </h3>
                    {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                        Most popular
                    </p>
                    ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-white">{tier.price[frequency.value]}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-300">{frequency.priceSuffix}</span>
                </p>
                <a
                    href={tier.href}
                    aria-describedby={tier.id}
                    className={classNames(
                    tier.mostPopular
                        ? 'bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500'
                        : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                    )}
                >
                    Buy plan
                </a>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                    {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                        <CheckIcon className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                        {feature}
                    </li>
                    ))}
                </ul>
                </div>
            ))}
            </div>
        </div>
        </div>
    </div>
  )
}
