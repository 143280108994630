import { Fragment } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, ArrowRightIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'
import styled, { keyframes } from 'styled-components';
import { FaArrowRight } from "react-icons/fa6";

const user = {
  name: 'Chelsea Hagon',
  email: 'chelsea.hagon@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Teams', href: '#', current: false },
  { name: 'Directory', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


// Use the same @keyframes definition for backgroundFade


const backgroundFade = keyframes`
  0% {
    background-color: #f79533;
  }
  14% {
    background-color: #f37055;
  }
  28% {
    background-color: #ef4e7b;
  }
  42% {
    background-color: #a166ab;
  }
  56% {
    background-color: #5073b8;
  }
  70% {
    background-color: #1098ad;
  }
  84% {
    background-color: #07b39b;
  }
  100% {
    background-color: #6fba82;
  }
`;
const StyledButton = styled.button`
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 11px;
  animation: ${backgroundFade} 3s infinite;
  outline: none;



`;
const AnimatedGradientText = styled.span`
  font-size: 24px; // or your desired size
  font-weight: bold; // assuming you want it bold
  color: white; // text color, might need adjustment based on your design
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; // Makes the text color transparent so the background shows
  animation: ${backgroundFade} 3s infinite;
  display: inline-block; // Ensures background-clip works correctly
`;
export default function Navbar() {
  return (
    <>
      {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
      <Popover
  as="header"
  className={({ open }) =>
    classNames(
      open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
      'shadow-sm lg:static lg:overflow-y-visible sticky top-0 z-50 bg-transparent'
    )
  }
>
        {({ open }) => (
          <>
            <div className="invisible lg:visible fixed z-10 inset-0 mx-auto  px-4 sm:px-6 lg:px-8 py-4 h-10 ">
              <div className="relative flex justify-between lg:gap-8  ">
                <div className="flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2">
                  <div className="flex flex-shrink-0 items-center">
                    <a href="#">
                      {/* <img
                        className="h-8 w-auto "
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Job Finder Ai"
                      /> */}
                            <AnimatedGradientText>Job AI</AnimatedGradientText>

                    </a>
                  </div>
                </div>
             
             
                <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                  {/* <button
                    type="button"
                    className="relative ml-5 flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
 
 <StyledButton>Start Applying <span> <FaArrowRight
   className='inline-block'
  /></span></StyledButton>

                </div>
              </div>
            </div>

        
          </>
        )}
      </Popover>
    </>
  )
}
