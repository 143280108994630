import './App.css';
import Navbar from './components/navbar'
import MainHero from './components/mainhero'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

// const SignUp = lazy(() => import('./routess/SignUp.js'));
import SignUp from './routes/SignUp'

function App() {
  return (
  //  <>
  //   <Navbar/>
  //   <MainHero/>
  //  </>

<Router>
<Suspense fallback={<div>Loading...</div>}>
  <Routes>
    <Route path="/" element={
     <>
    <Navbar/>
    <MainHero/>
   </>
    } />
    <Route path="/signup" element={<SignUp />} />
  </Routes>
</Suspense>
</Router>
  );
}

export default App;
