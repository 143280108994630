import { MapPinIcon } from '@heroicons/react/20/solid'
import './styles/mainHero.css'
import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from 'react'; // Import useEffect and useState
// Import the functions you need from the SDKs you need
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByPKK68FG1UNPf-dRkPoq7GCJZGkUdZxg",
  authDomain: "jobai-fc49f.firebaseapp.com",
  projectId: "jobai-fc49f",
  storageBucket: "jobai-fc49f.appspot.com",
  messagingSenderId: "4136311006",
  appId: "1:4136311006:web:b23a32cadd6902a6cfca4f",
  measurementId: "G-F5RDHZ0BF2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

const sendLoginLink = async (email) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'http://localhost:3000/signup',
    // This must be true.
    handleCodeInApp: true,
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(email)) {
    alert('Please enter a valid email address');
    return;
  }
  
  try {


    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    window.localStorage.setItem('emailForSignIn', email);
    alert('Sign-in link sent to your email address');
  } catch (error) {
    console.error(error);
    // Handle errors here, such as showing a notification
  }
};


const jobs = [
  {
    jobTitle: 'Senior Software Engineer',
companyName:'The Home Depot',
backgroundColor:'orange',
logoPath:'./company_logos/homedepot.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Project Manager',
companyName:'The Home Depot',
backgroundColor:'black',
logoPath:'./company_logos/3M.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Software Engineer',
companyName:'The Home Depot',
backgroundColor:'lightgrey',
logoPath:'./company_logos/airbnb.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Director of Engineering',
companyName:'The Home Depot',
backgroundColor:'darkblue',
logoPath:'./company_logos/amazon.webp',
interview:1,
offer:1
},
  {
    jobTitle: 'Product Manager',
companyName:'The Home Depot',
backgroundColor:'darkgrey',
logoPath:'./company_logos/apple.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Lead Engineer',
companyName:'The Home Depot',
backgroundColor:'red',
logoPath:'./company_logos/bofa.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'QA Engineer',
companyName:'The Home Depot',
backgroundColor:'lightblue',
logoPath:'./company_logos/capone.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Backend Engineer',
companyName:'The Home Depot',
backgroundColor:'red',
logoPath:'./company_logos/delta.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Engineering Manager',
companyName:'The Home Depot',
backgroundColor:'purple',
logoPath:'./company_logos/fedex.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Product Owner',
companyName:'The Home Depot',
backgroundColor:'orange',
logoPath:'./company_logos/Fiserv.jpeg',
interview:1,
offer:1
},
  {
    jobTitle: 'Head of Engineering',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/ford.png',
interview:1,
offer:1
},
  {
    jobTitle: 'VP of Engineering',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/google.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Software Engineer',
companyName:'Snapchat',
backgroundColor:'yellow-400',
logoPath:'./company_logos/snapchat.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Senior Software Engineer',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/jpmorgan.jpg',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Systems Engineer Sr',
companyName:'Lockheed Martin',
backgroundColor:'orange-400',
logoPath:'./company_logos/lockheedmartin.jpg',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Software Engineer',
companyName:'Lyft',
backgroundColor:'pink-400',
logoPath:'./company_logos/lyft.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'ML Engineer',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/Meta.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Senior Software Engineer',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/microsoft.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Business Analyst',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/morganstanley.jpg',
interview:1,
offer:1
},
  {
    jobTitle: 'CTO',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/ncr.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Engineering Manager',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/netflix.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Machine Learning Engineer',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/nvidia.png',
interview:1,
offer:1
},
  {
    jobTitle: 'IT Engineer - SRE',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/openai.jpg',
interview:1,
offer:1
},
  {
    jobTitle: 'Job Title',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/paypal.png',
interview:1,
offer:1
},

  {
    jobTitle: 'Job Title',
companyName:'The Home Depot',
backgroundColor:'orange-400',
logoPath:'./company_logos/raytheon.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Senior Manager, Operations',
companyName:'Salesforce',
backgroundColor:'blue-200',
logoPath:'./company_logos/Salesforce.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Sales Representative',
companyName:'State Farm',
backgroundColor:'red-400',
logoPath:'./company_logos/statefarm.webp',
interview:1,
offer:1
},
  {
    jobTitle: 'Equipment Engineer, Service Operations',
companyName:'Tesla',
backgroundColor:'red-400',
logoPath:'./company_logos/tesla.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Machine Learning Engineer',
companyName:'Tik Tok',
backgroundColor:'black',
logoPath:'./company_logos/tiktok.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Data Engineer',
companyName:'Zoom',
backgroundColor:'orange-400',
logoPath:'./company_logos/zoom.webp',
interview:1,
offer:1
},
  {
    jobTitle: 'iOS Developer',
companyName:'Uber',
backgroundColor:'orange-400',
logoPath:'./company_logos/uber.jpg',
interview:1,
offer:1
},
  {
    jobTitle: 'Job Title',
companyName:'Verizon',
backgroundColor:'orange-400',
logoPath:'./company_logos/verizon.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Job Title',
companyName:'Visa',
backgroundColor:'orange-400',
logoPath:'./company_logos/visa.png',
interview:1,
offer:1
},
  {
    jobTitle: 'Software Engineer',
companyName:'GitHub',
backgroundColor:'slate-900',
logoPath:'./company_logos/github.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Senior Software Engineer',
companyName:'Warner Bros',
backgroundColor:'blue-800',
logoPath:'./company_logos/warnerBros.svg.png',
applied:1,
interview:1,
offer:1
},
  {
    jobTitle: 'Director of Engineering',
companyName:'X',
backgroundColor:'orange-400',
logoPath:'./company_logos/x.png',
interview:1,
offer:1
}

]

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      let j = Math.floor(Math.random() * (i + 1));
      // Swap elements at indices i and j
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}


const backgroundFade = keyframes`
0% {
  background-color: #f79533;
}
14% {
  background-color: #f37055;
}
28% {
  background-color: #ef4e7b;
}
42% {
  background-color: #a166ab;
}
56% {
  background-color: #5073b8;
}
70% {
  background-color: #1098ad;
}
84% {
  background-color: #07b39b;
}
100% {
  background-color: #6fba82;
}
`;
 
const StyledButton = styled.button`

  padding: 10px 10px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border-radius: 11px;
  animation: ${backgroundFade} 3s infinite;
  outline: none;



`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(4);

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;



const BackgroundGrid = styled.div`
  position: absolute;
  
  top: -400px;
  left: -140px;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  z-index: 0;
  transform: rotate3d(1, -1, 1, 45deg);
`;
// overflow: hidden;
// position: relative; 


// Animation for moving rows
const moveRow = (speed) => keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
`;

// Styled grid row
const GridRow = styled.div`
  display: flex;
  animation: ${(props) => moveRow(props.speed)} ${(props) => props.speed}s linear infinite;
  height: 100%;
`;

// Styled grid cell
const GridCell = styled.div`
width: 350px;
height: 300px;

  flex: 1;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  &:last-child {
    border-right: none;
  }
`;
const JobInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  text-align: center;
`;

const CompanyName = styled.div`
  font-weight: bold;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
`;

const Logo = styled.img`
width:40%;
  margin: 10px auto;
`;

const Stage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
`;

const Circle = styled.span`
  height: 5px;
  width: 5px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export default function Mainhero() {
  
  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Retrieve the email from local storage
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. You will need to ask the
        // user for their email again.
        email = window.prompt('Please provide your email for confirmation');
      }
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // Signed in user's information.
          const user = result.user;
          // You can now redirect the user or show them content
        })
        .catch((error) => {
          // Handle errors here, such as showing a notification
          console.error(error);
        });
    }
  }, []);
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendLoginLink(email);
  };

  const numberOfRows = 7;
  const rowSpeeds = [80, 60, 70, 100, 80, 100, 80]; 
  const rowPosition= [0,300,600,900,1200,1500,1800]

  const [shuffledJobs, setShuffledJobs] = useState([]);
  const [shuffledJobs1, setShuffledJobs1] = useState([]);
  const [shuffledJobs2, setShuffledJobs2] = useState([]);
  const [shuffledJobs3, setShuffledJobs3] = useState([]);
  const [shuffledJobs4, setShuffledJobs4] = useState([]);

  // Shuffle jobs when the component mounts
  useEffect(() => {
    setShuffledJobs(shuffleArray([...jobs])); 
    setShuffledJobs1(shuffleArray([...jobs])); 
    setShuffledJobs2(shuffleArray([...jobs])); 
    setShuffledJobs3(shuffleArray([...jobs])); 
    setShuffledJobs4(shuffleArray([...jobs])); 
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <>
    
    {/* <div className="relative isolate overflow-hidden bg-gray-900"> */}
    <div className="relative isolate overflow-hidden bg-black/90">
      
    <BackgroundGrid >
    <Overlay />
{/* {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
  <GridRow style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}>
    {Array.from({ length: 50 }).map((_, cellIndex) => (
      <GridCell key={cellIndex} />
    ))}
  </GridRow>
))} */}
{/* {Array.from({ length: numberOfRows }).map((_, rowIndex) => ( */}

  <GridRow  style={{position:'absolute', top: rowPosition[0]}} key={0} speed={rowSpeeds[0]}>
  {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
   {/* {jobs.map((job, index) => ( */}
   {shuffledJobs.map((job, index) => (

    <GridCell  >  
    {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
      <JobInfo  >
        <CompanyName>{job.jobTitle}</CompanyName>
        <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>

))} 
  </GridRow>
  <GridRow  style={{position:'absolute', top: rowPosition[1]}} key={1} speed={rowSpeeds[1]}>
  {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
   {/* {jobs.map((job, index) => ( */}
   {shuffledJobs1.map((job, index) => (

    <GridCell  >  
    {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
      <JobInfo  >
        <CompanyName>{job.jobTitle}</CompanyName>
        <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>

))} 
  </GridRow>
  <GridRow  style={{position:'absolute', top: rowPosition[2]}} key={2} speed={rowSpeeds[2]}>
  {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
   {/* {jobs.map((job, index) => ( */}
   {shuffledJobs2.map((job, index) => (

    <GridCell  >  
    {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
      <JobInfo  >
        <CompanyName>{job.jobTitle}</CompanyName>
        <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>

))} 
  </GridRow>

  <GridRow  style={{position:'absolute', top: rowPosition[3]}} key={3} speed={rowSpeeds[3]}>
  {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
   {/* {jobs.map((job, index) => ( */}
   {shuffledJobs3.map((job, index) => (

    <GridCell  >  
    {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
      <JobInfo  >
        <CompanyName>{job.jobTitle}</CompanyName>
        <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>

))} 
  </GridRow>
  <GridRow  style={{position:'absolute', top: rowPosition[4]}} key={4} speed={rowSpeeds[4]}>
  {/* <GridRow  style={{position:'absolute', top: rowPosition[rowIndex]}} key={rowIndex} speed={rowSpeeds[rowIndex]}> */}
   {/* {jobs.map((job, index) => ( */}
   {shuffledJobs4.map((job, index) => (

    <GridCell  >  
    {/* <GridCell style={{ backgroundColor: job.backgroundColor }}>   */}
      <JobInfo  >
        <CompanyName>{job.jobTitle}</CompanyName>
        <Location><MapPinIcon className='h-2 pr-1'/> Location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>

))} 
  </GridRow>
{/* ))} */}
{/* {jobs.map((job, index) => (
  <GridRow key={index} style={{ backgroundColor: job.backgroundColor ,}}>
    <GridCell>
      <JobInfo>
        <CompanyName>{job.companyName}</CompanyName>
        <Location><ChevronRightIcon /> location</Location>
        <Logo src={job.logoPath} alt="logo" />
        <Stage><Circle />Applied</Stage>
        <Stage><Circle />Interviewing</Stage>
        <Stage><Circle />Offer</Stage>
      </JobInfo>
    </GridCell>
  </GridRow>
))} */}

</BackgroundGrid>


     
      <div className="h-[680px] lg:h-[780px] mx-auto max-w-7xl  px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-44 relative">
        <div className="lg:mx-auto  flex-shrink-0 lg:mx-0 md:max-w-[640px] xl:max-w-[740px] lg:pt-8 pb-4 lg:pb-0">
       
          <div className="laurel ">
					<span className="laurel-text">
						#1 AI Job Assistant
					</span>
					<span className="laurel-stars	">
						<img alt='star' className="laurel-stars-img" width="13" height="13" src="/star.svg"/>
            <img alt='star' className="laurel-stars-img" width="13" height="13" src="/star.svg"/>
            <img alt='star' className="laurel-stars-img" width="13" height="13" src="/star.svg"/>
            <img alt='star' className="laurel-stars-img" width="13" height="13" src="/star.svg"/>
            <img  alt='star' className="laurel-stars-img" width="13" height="13" src="/star.svg"/>
			 </span>
					<img alt='wreath' width="185" height="58" className="laurel-img" src="/laurel.svg"/>
				</div>

          <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:text-6xl ">
            Apply to jobs with ease using AI
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
            fugiat veniam occaecat fugiat aliqua.
          </p>
       
        </div>
 <form className=' my-auto' onSubmit={handleSubmit}>
              <div className="mx-auto my-auto bg-black/40 rounded-lg	flex-col flex px-5 py-5 gap-y-2 border-gray-700 border w-[350px] ">
             

                <input 
                name="myInput"                 
                onChange={handleEmailChange}  
                value={email}
                placeholder='Type your email...' 
                className='py-3 px-4 rounded-xl  ' />
                
                <StyledButton type="submit">Start applying with AI now</StyledButton>

              <p className='text-white text-xs text-center'>If you already have an account, we'll log you in</p>
              </div>
              </form>

      </div>
    </div>
    </>
  )
}
